import { AddChannelList, DelChannelList } from "@/api/GoShopApi";
import { shopUrl } from "@/common/utils";
import ChannelService from "@/services/Channel/ChannelService";
import ShopService from "@/services/Shop/ShopService";
import { ElMessage, ElMessageBox } from "element-plus";
import { ref } from "vue";
export default function ChannelInfo() {
    const loading = ref(false);
    const dialogVisible = ref(false);
    const currentShop = ShopService.GetCurrentShop();
    const channelList = ChannelService.GetChannelList(); // 渠道列表
    console.log(currentShop);
    const id = currentShop.id;
    const addBtn = (form) => {
        loading.value = true;
        if (!form.name)
            return ElMessage.warning('渠道名称不能为空~');
        AddChannelList({ merchantStoreId: id, name: form.name }).then(async (res) => {
            dialogVisible.value = false;
            await ChannelService.UpdateChannelList({ id, pageNum: 1, pageSize: 30 });
            loading.value = false;
        }).catch(() => {
            loading.value = false;
        });
    };
    const delChannel = async (item, index) => {
        await ElMessageBox.confirm('确定删除该活动渠道？', '删除', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        });
        const params = {
            id: item.id
        };
        loading.value = true;
        DelChannelList(params).then(async (res) => {
            await ChannelService.UpdateChannelList({ id, pageNum: 1, pageSize: 30 });
            loading.value = false;
        });
    };
    const getChannelUrl = (channelNo) => {
        let path = window.location.href.split("?")[0].split("#")[0]; //分割url
        path = path.substring(0, path.length - 1);
        return `${ShopService.GetCurrentShop().storeNo}.${shopUrl()}?from=${channelNo}`;
    };
    return {
        dialogVisible,
        loading,
        channelList,
        getChannelUrl,
        delChannel,
        addBtn,
    };
}
